<template>
    <div class="myIntegral">
        <div class="myIntegral_information">
            <p>我的积分</p>
            <p>
                <span>{{integral}}</span>
                <span>积分</span>
            </p>
        </div>
        <div class="myIntegral_list">
            <div class="myIntegral_list_title">积分明细</div>

            <div class="myIntegral_list_item" v-for="(item,index) in dataList" :key="index">
                <div class="myIntegral_list_item_left">
                    <span>{{item.type==1?'课程赠送':'课程消费'}}</span>
                    <span>{{item.intime}}</span>
                </div>
                <div class="myIntegral_list_item_right">{{item.status==1?'+':'-'}}{{item.pointValue}}</div>
            </div>

            <div class="noMore" v-if="dataList.length ==0">
                <div class="coursediv_empty_img">
                    <img src="@/assets/class_empty.png" alt="">
                </div>
                暂无数据
            </div>

        </div>
    </div>
</template>

<script>
import { point_list } from "../../api/myself"
export default {
    name: 'myIntegral',
    data() {
        return {
            integral:0,
            dataList:[]
        }
    },
    mounted() {
        this.point_list();
    },
    methods: {
        point_list() {
            point_list().then(res => {
                console.log(res);
                this.integral = res.data.data.totalPointValue
                this.dataList = res.data.data.pointChildList
            })
        }
    },

}
</script>

<style>
</style>